<template>
   <div class="hovered-btn">
			<svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11.5 23C12.8807 23 14 21.8807 14 20.5C14 19.1193 12.8807 18 11.5 18C10.1193 18 9 19.1193 9 20.5C9 21.8807 10.1193 23 11.5 23Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.5 14.5C12.8807 14.5 14 13.3807 14 12C14 10.6193 12.8807 9.5 11.5 9.5C10.1193 9.5 9 10.6193 9 12C9 13.3807 10.1193 14.5 11.5 14.5Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.5 6C12.8807 6 14 4.88071 14 3.5C14 2.11929 12.8807 1 11.5 1C10.1193 1 9 2.11929 9 3.5C9 4.88071 10.1193 6 11.5 6Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
			<svg  class="hovered" width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M6 3.75C6 1.67893 7.67893 0 9.75 0H13.25C15.3211 0 17 1.67893 17 3.75V24.25C17 26.3211 15.3211 28 13.25 28H9.75C7.67893 28 6 26.3211 6 24.25V3.75Z" fill="white"/>
					<path d="M11.5 25C12.8807 25 14 23.8807 14 22.5C14 21.1193 12.8807 20 11.5 20C10.1193 20 9 21.1193 9 22.5C9 23.8807 10.1193 25 11.5 25Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.5 16.5C12.8807 16.5 14 15.3807 14 14C14 12.6193 12.8807 11.5 11.5 11.5C10.1193 11.5 9 12.6193 9 14C9 15.3807 10.1193 16.5 11.5 16.5Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M11.5 8C12.8807 8 14 6.88071 14 5.5C14 4.11929 12.8807 3 11.5 3C10.1193 3 9 4.11929 9 5.5C9 6.88071 10.1193 8 11.5 8Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
	</div>
</template>