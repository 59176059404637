<template>
    <div>       
        <footer class="footer" v-bind:class="classValue">
            <div class="footer-item">
                <p class="copyrights font-roboto">Copyright © 2024 Sig2 Labs Inc. All rights reserved</p>
            </div>
            <div class="footer-item footer-links">
                <nav class="navbar d-flex align-items-center ">
                    <span @click="showAboutModal = true" class="nav-link">About</span>
                    <a href="https://share.hsforms.com/1QjgP_6vmQvW3_uV9lMCEewr40qz" class="nav-link" target="_blank">Support</a>
                    <span @click="this.$router.push('/terms')" class="nav-link">Terms</span>
                    <span @click="this.$router.push('/privacy')" class="nav-link">Privacy</span>
                </nav>
            </div>
        </footer>
        <div data-app>
            <AboutModal v-model="showAboutModal" @closed="showAboutModal = false"/>
            <TermsConditionsModal v-model="showTermsModal" @closed="onCloseModal"/>
            <PrivacyPolicyModal v-model="showPrivacyModal" @closed="onCloseModal"/>
        </div>

    </div>
</template>
<script>
    import AboutModal from '../modals/AboutModal.vue';
    import TermsConditionsModal from '../modals/TermsConditionsModal.vue';
    import PrivacyPolicyModal from '../modals/PrivacyPolicyModal.vue';
    import { ref } from 'vue';

    export default {
        name: 'Footer',
        props: {
            classValue: String,
        },
        components: {
            AboutModal, TermsConditionsModal, PrivacyPolicyModal
        },
        watch: {
            "$route.path": {
            immediate: true,
                handler(newPath) {
                    if(newPath == '/terms' && !this.showTermsModal){
                        this.showTermsModal = true
                    } else if (newPath ==  '/privacy' && !this.showPrivacyModal){
                        this.showPrivacyModal = true;
                    }
                },
            },
        },
        data() {
            return {
                showAboutModal: false,
                showTermsModal: false,
                showPrivacyModal: false,
            };
        },
        beforeRouteEnter(to, from, next) {
            alert(from)
        },
        methods:{
            onCloseModal(){
                this.showTermsModal = false; 
                this.showPrivacyModal = false;
                //when user is logged-in in other tab, and open the terms and privacy page with deep link, 
                //closing that will take the user to messages page, else it will go to the home page
                 if(localStorage.getItem('previousPage') == "/"){
                    if (this.$root.userdetail?.user[0].UserID){
                        window.location.href = '/messages';    
                    } else {
                        window.location.href = '/';
                    }
                } else { 
                    //when terms and pricacy pages open directly
                    this.$router.back();
                }
            }
        } 
    };
</script>
<style lang="scss" scoped>

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    width: 100%;
    position: relative;
    bottom: unset;
    padding: 0 0 40px;
    @media screen and (max-width: 1880px) {
      padding: 0 0 40px;
    }
    @media screen and (max-width: 1580px) {
      padding: 0 0 40px;
    }
    @media screen and (max-width: 1080px) {
      padding: 0 0 40px;
    }
    @media screen and (max-width: 992px) {
        padding: 0 0 30px;
        flex-direction: column-reverse;
        gap: 20px;
    }
    @media screen and (max-width: 568px) {
      padding: 0 0 20px;
    }

    &.home-footer{
        padding: 0 80px 40px;
        @media screen and (max-width: 1880px) {
            padding: 0 12% 40px;
        }
        @media screen and (max-width: 1580px) {
            width: 93%;
            max-width: 1320px;
            margin: 0 auto;
            padding: 0 0 40px;
        }
        @media screen and (max-width: 1080px) {
            margin: 0;
            width: 100%;
            padding: 0 6% 40px;
        }
        @media screen and (max-width: 992px) {
            padding: 0 6% 30px;
            flex-direction: column-reverse;
            gap: 20px;
        }
        @media screen and (max-width: 568px) {
            padding: 0 5% 20px;
        }
        .footer-item{
            background: rgb(52, 77, 122, 0.35) !important;
            .copyrights, .navbar .nav-link{
                color: #ffffff !important;
            }
        }
    }
}

.footer-item{
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.35);
    padding: 0 30px;
    min-height: 40px;
    display: flex;
    align-items: center;
    width: 400px;
    justify-content: flex-start;
    @media screen and (max-width: 568px) {
        padding: 10px 12px;
        width: 100%;
        justify-content: center;
        width: 100%;
    }

    .copyrights{
        color: #344D7A;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 114.286%;
        @media screen and (max-width: 568px) {
            text-align: center;
        }
        @media screen and (max-width: 380px) {
            font-size: 12px;
        }
    }
    .navbar{
        gap: 53px;
        padding: 0;
        @media screen and (max-width: 568px) {
            gap: 20px;
        }
        .nav-link{
            color: #344D7A;
            text-align: center;
            font-family: "Roboto";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.286%;
            cursor: pointer;
        }
        .nav-link:hover {
            text-decoration: underline;
        }

    }
}
</style>