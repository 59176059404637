<template>
	<div>
	  <div class="router-view-wrapper">
		<div class="landing-page app-container d-flex justify-content-between">
		  <div class="landing-text-section">
			<h2 class="font-roboto text-primary">Right Place. Right Time.</h2>
			<h4 class="font-roboto text-primary fw-medium ">Geofence Text-to-Speech Messages</h4>
		  </div>
	  
		</div>
		<Footer :classValue="'home-footer'"/>
	  </div>
	  <div data-app>
		  <WishlistModal v-model="showModal" :usersname="usersname" />
	  </div>
	</div>
  </template>

<script>
import WishlistModal from '../components/modals/WishlistModal.vue'
import Footer from './common/Footer.vue';
//import 'vue-material-design-icons/styles.css';
export default{
	name: "authnotallowed",
	components:{Footer, WishlistModal},
	data: () => ({
		usersname : "",
		showModal: true
	}),
	mounted(){
		console.log('no auth ::', this.$root)
	    // if user is added redirect back to home page
		if (this.$root.loggedInUser && !this.$root.unauthorizeuser){
			document.location.href="/";
		}
    this.usersname = this.$root.loggedInUser;

	},
	methods :{
		// logout(){
		// 	this.$root.logout();
		// }
	}

}

</script>

<style lang="scss" scoped>
.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.landing-page{
  padding-top: 70px;
  padding-bottom: 70px;
  @media screen and (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
    min-height: max-content;
    gap: 30px;
  }
  @media screen and (max-width: 568px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-text-section{
    h2{
      font-size: 64px;
      line-height: 72px;
      font-weight: 800;
      @media screen and (max-width: 1280px) {
        font-size: clamp(28px,5vw, 56px);
      }
    }
    h4{
      font-size: 42px;
      line-height: 72px;
      @media screen and (max-width: 1440px) {
        font-size: 42px;
      }
      @media screen and (max-width: 1280px) {
        font-size: clamp(18px, 3.2vw, 40px);
      }
    }
  }
}
</style>